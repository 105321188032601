angular.module('enervexSalesappApp').controller('VentDataCtrl', function($scope, $uibModalInstance, Auto, setValue, DoUndo, Util, context, paint, ProductSubtype, FittingMetaUtil, Product, SelectionMetaUtil, LengthUtil, ExcludeMetaUtil, LayoutUtil, CommandManager) {
	var checkObj = null,
		enforcePrecision = true;
	var prevWatch = null;
	var prevWatch = null,
		prevWatch2 = null;

	$scope.showClose = false;
	$scope.showApply = false;
	$scope.ctx = {
		allAccessories:false,
		allExcludes: false
	}

	$scope.cloneProducts = true
	$scope.showStackAdmin = function() {
		// return false;
		return $scope.isAdmin()
	}
	$scope.toggleAccessories = function() {
		$scope.ctx.allAccessories = !$scope.ctx.allAccessories
	}
	$scope.supportECM = true;
	if (!$scope.design.stack.selectionMetas) {
		$scope.design.stack.selectionMetas = []
	}
	if (!$scope.design.stack.fittingMetas) {
		$scope.design.stack.fittingMetas = []
	}

	$scope.startingConfigs = [
		"type",
		"product"
	]

	$scope.overrideTypes = [{
		id:"none",
		name: "None"
	},{
		id:"product",
		name: "Product"
	},{
		id:"customProduct",
		name: "Custom Product"
	}]
	
	$scope.startingSubtypes = Util.getSubtypes("StartingPoint", $scope.allProductSubtypes, $scope.design)

	$scope.couplerTypes = [
		"ECCENTRIC",
		"CONCENTRIC",
	];
	$scope.fittingTypes = [
		{
			name: 'Section',
			value: 'section'
		},
		{
			name: 'Cover Accessory',
			value: 'productSubtype'
		},
	]
	$scope.selectEconomizer = function() {
		// Util.updateEconomizer($scope.design, $scope.economizers)
		var economizer = Util.getSelectedEconmizer($scope.vent.ECM.economizer, $scope.economizers)
		if (economizer) {
			if (!$scope.vent.ECM) {
				$scope.vent.ECM = {}
			}
			$scope.vent.ECM.KValue = economizer.kValue;
			$scope.vent.ECM.MaxT = economizer.maxTempOut;
			$scope.vent.ECM.Dia = economizer.centerPointA.dim1;
		}
	}
	$scope.hasAccessories = function() {
		var hasOne = _.find($scope.productSubtypes, function(p){
			return p.quantity > 0
		})
		return (hasOne) ? true: false
	}
	$scope.canINLtransition = function() {
		var inlineFanTransition = $scope.configs.INLINE_FAN_TRANSITION_MODE.value == 'error'
		if (inlineFanTransition && $scope.selectedConnections.length == 1){
			var v = $scope.selectedConnections[0];
			return Util.isExhaustOnly($scope.design) && (v.Fit1 == 'INL' || v.Fit2 == 'INL')
		}
	}
	$scope.selectedFanProduct = $scope.getInlinFanProduct($scope.design.exhFan)
	function getStackOptions() {
		if ($scope.selectedConnections.length == 1){
			var vent = $scope.selectedConnections[0];
			var options = $scope.stackOptions
			return _.map(options, function(stackOption){
				var existing = _.find($scope.design.stack.stackOptions, function(o){
					return o.type == "section" && o.GUIidentPath == vent.GUIidentPath && o.stackOption == stackOption._id
				})
				if (existing) {
					return existing
				} else {
					var r = {
						stackOption: stackOption._id,
						type: 'section',
						GUIidentPath: vent.GUIidentPath,
						included: true
					}
					$scope.design.stack.stackOptions.push(r)
					return r
				}
			})
		} else {
			return []
		}
	}
	function getFittingTargets() {
		var result = []
		result.push({
			value: null,
			name: ""
		})
		if ($scope.selectedConnections.length == 1){
			var vent = $scope.selectedConnections[0];
			result.push({
				value: vent.GUIidentPath,
				name: "Section " + vent.GUIidentPath + " (OUTLET)"
			})
			var inlets = Util.getInlets(vent, $scope.design)
			_.each(inlets, function(inlet)  {
				var isLateral = inlet.X1 != inlet.X2 || inlet.Z1 != inlet.Z2
				result.push({
					value:inlet.GUIidentPath,
					name: "Section " + inlet.GUIidentPath + (isLateral ? " (LATERAL" : "")
				})
			})
		}
		return result
	}
	$scope.sectionNumber = function() {
		if ($scope.selectedConnections.length == 1){
			var vent = $scope.selectedConnections[0];
			return parseInt(vent.GUIident)
		}
	}
	$scope.getFit1 = function() {
		return _.find($scope.fitting1.options, function(vm){
			return (vm.code == $scope.vent.Fit1)
		})
	}
	$scope.getFit2 = function() {
		return _.find($scope.fitting2.options, function(vm){
			return vm.code == $scope.vent.Fit2;
		})
	}

	$scope.productSubtypeName = function(id) {
		var result = _.find($scope.allProductSubtypes, function(productSubtype){
			return productSubtype._id == id
		})
		return (result) ? result.name : 'unknown'
	}
	$scope.isTermination = function(vent){
		return Util.isTermination(vent)
	}
	function getProductSubtypes() {
		if ($scope.selectedConnections.length == 1){
			var vent = $scope.selectedConnections[0];
			var accessories = Util.getSubtypes("Accessory", $scope.allProductSubtypes, $scope.design)
			accessories = _.filter(accessories, function(productSubtype){
				return productSubtype.activeProducts
			})
			$scope.productSubtypes = _.map(accessories, function(subtype){
				var existing = _.find($scope.design.stack.accessoryMetas, function(ac){
					return ac.productSubtype == subtype._id && vent.GUIidentPath == ac.GUIidentPath
				})
				if (existing) {
					return existing
				} else {
					var item = {
						productSubtype: subtype._id,
						product: null,
						quantity: 0,
						GUIidentPath: vent.GUIidentPath
					}
					$scope.design.stack.accessoryMetas.push(item)
					return item
				}
			})
			$scope.newItem = createNewItem();
			if (Util.isTermination(vent)) {
				$scope.terminationSubtypes = Util.getSubtypes("TerminationPoint", $scope.allProductSubtypes, $scope.design)
				$scope.selectionMeta = SelectionMetaUtil.getSelectionMeta(vent, $scope.design, Util.getProductType('TerminationPoint',$scope.allProductTypes)._id)
			} else {
				$scope.selectionMeta = SelectionMetaUtil.getSelectionMeta(vent, $scope.design, null)
			}
			$scope.inletCoverSubtypes = _.filter($scope.allProductSubtypes, function(subtype){
				return subtype.inletCover
			})
		}
	}
	$scope.changeExclude = function(exclude) {
		if (exclude.selected) {
			//add to excludeMeta
			var existing = _.find($scope.excludeMeta.excludeSubtypes, function(o){
				return o == exclude._id
			})
			if (!existing) {
				$scope.excludeMeta.excludeSubtypes.push(exclude._id)
			}
		} else {
			//remove from excludemeta
			$scope.excludeMeta.excludeSubtypes = _.filter($scope.excludeMeta.excludeSubtypes, function(o){
				return o != exclude._id
			})
		}
	}
	function getExcludeMeta() {
		if ($scope.selectedConnections.length == 1){
			var vent = $scope.selectedConnections[0];
			$scope.excludeMeta = ExcludeMetaUtil.getExcludeMeta(vent, $scope.design)
			//add missing
			$scope.excludeSubtypes = _.map($scope.allProductSubtypes, function(productSubtype){
				var found = _.find($scope.excludeMeta.excludeSubtypes, function(excludeSubtype){
					return excludeSubtype == productSubtype._id
				})
				var result = _.clone(productSubtype)
				result.selected = (found) ? true : false
				return result
			})
		} else {
			$scope.excludeSubtypes = []
		}
	}

	$scope.getAccessoryProducts = function() {
		var vent = $scope.selectedConnections[0]
		return  _.filter($scope.design.stack.accessoryMetas, function(am){
			return vent.GUIidentPath == am.GUIidentPath && am.product != null
		})
	}
	$scope.addProduct = function(item, event){
		event.preventDefault()
		$scope.design.stack.accessoryMetas.push(item);
		$scope.newItem = createNewItem();
	}
	$scope.clearForm = function(){
		$scope.newItem = createNewItem();
	}
	function createNewItem() {
		return {
			quantity: 1,
			GUIidentPath: $scope.selectedConnections[0].GUIidentPath
		}
	}
	$scope.autoCompleteOptionsOverrideProduct = {
		minimumChars: 1,
		dropdownWidth: "500px",
		containerCssClass:"ventcontroller-autocomplete",
		data: function (term) {
			var fitting = _.find($scope.fittings, function(fitting){
				return fitting.code == $scope.vent.Fit1
			})
			var params = {
				limit:20,
				term: term,
				manufacturer: $scope.manufacturer._id,
				fitting: fitting._id
			};

			if ($scope.configs.allow_deleted_manual_products != 'true'){
				params.deleted = false;
			}
			// params.isAggregate = 'false'
			return Product.query(params).$promise.then(function(products){
				$scope.productsPage = products
				return _.map(products, function(product){
					return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
				});
			})
		},
		itemSelected: function(item){
			var fullProduct = _.find($scope.productsPage, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.fittingMeta.override.product = ($scope.cloneProducts) ? _.clone(fullProduct) : fullProduct;
		}
	}
	$scope.autoCompleteOptionsOverrideCustomProduct = {
		minimumChars: 1,
		dropdownWidth: "500px",
		containerCssClass:"ventcontroller-autocomplete",
		data: function (term) {
			var fitting = _.find($scope.fittings, function(fitting){
				return fitting.code == $scope.vent.Fit1
			})
			var products = _.filter($scope.design.customComponents, function(product){
				return product.fitting == fitting._id
			})
			return _.map(products, function(product){
				return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
			});
		},
		itemSelected: function(item){
			var fullProduct = _.find($scope.design.customComponents, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.fittingMeta.override.customProduct = ($scope.cloneProducts) ? _.clone(fullProduct) : fullProduct;
		}
	}
	$scope.autoCompleteOptions = {
		minimumChars: 1,
		dropdownWidth: "500px",
		containerCssClass:"ventcontroller-autocomplete",
		data: function (term) {
			var params = {
				limit:20,
				term: term,
				manufacturer: $scope.manufacturer._id
			};

			if ($scope.configs.allow_deleted_manual_products != 'true'){
				params.deleted = false;
			}
			// params.isAggregate = 'false'
			return Product.query(params).$promise.then(function(products){
				$scope.productsPage = products
				return _.map(products, function(product){
					return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
				});
			})
		},
		itemSelected: function(item){
			var fullProduct = _.find($scope.productsPage, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.newItem.product = fullProduct;
			fullProduct.formattedPrice = accounting.formatMoney(fullProduct.price);
		}
	}
	$scope.applianceAutoCompleteOptions = {
		minimumChars: 1,
		dropdownWidth: "500px",
		containerCssClass: 'appliance-modal-autocomplete',
		data: function (term) {
			return Product.query({
				limit:20,
				term: term,
				manufacturer: $scope.manufacturer._id
			}).$promise.then(function(products){
				$scope.productsPage = products
				return _.map(products, function(product){
					return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
				});
			})
		},
		itemSelected: function(item){
			$scope.ctx.formChanged = true;
			var fullProduct = _.find($scope.productsPage, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.selectedStartingProductNavId = fullProduct.navId
			$scope.selectedStartingProduct = fullProduct;
			$scope.selectionMeta.startingPointProduct = fullProduct._id
		}
	}
	$scope.setSubtypeCount = function(subtype, quantity) {
		if ($scope.selectedConnections.length == 1){
			var vent = $scope.selectedConnections[0];
			var existing = _.find($scope.design.stack.accessoryMetas, function(ac){
				return ac.productSubtype._id == subtype._id && ac.GUIidentPath == vent.GUIidentPath
			})
			if (existing) {
				existing.quantity = quantity
			} else {
				$scope.design.stack.accessoryMetas.push({
					productSubtype: subtype,
					product: null,
					quantity: quantity,
					GUIidentPath: vent.GUIidentPath
				})
			}
		}
	}
	function getSectionBomItems() {
		if ($scope.design && $scope.design.items && $scope.selectedConnections.length == 1 ) {
			var items = _.map($scope.design.items, function(item) {
				if (item.source && item.source.stack > 0) {
					var section = _.find(item.sections, function(section) {
						return section.section == $scope.selectedConnections[0].GUIidentPath
					})
					if (section) {
						var base = _.clone(item)
						base.sectionQuantity = section.quantity
						base.sectionTotalList = section.quantity * base.list
						return base;
					}
				}
			})
			return _.compact(items)
		}
	}
	$scope.getSectionMetas = function() {
		try {
			var result = _.filter($scope.design.stack.sectionMetas, function(meta) {
				return $scope.selectedConnections.length == 1 && meta.GUIidentPath == $scope.selectedConnections[0].GUIidentPath && meta.type != "sawtooth"
			})
			return result
		}catch(e) {
			console.log("error getting sections", e)
			throw e
		}
	}
	$scope.getSawtoothSectionMeta = function() {
		try {
			var result = _.find($scope.design.stack.sectionMetas, function(meta) {
				return $scope.selectedConnections.length == 1 && meta.GUIidentPath == $scope.selectedConnections[0].GUIidentPath && meta.type == "sawtooth"
			})
			return result
		}catch(e) {
			console.log("error getting sections", e)
			throw e
		}
	}
	$scope.resetFittingMeta = function($event) {
		if ($event){
			$event.preventDefault()
		}
		if ($scope.selectedConnections.length == 1 && $scope.design.stack && $scope.design.include.stack) {
			var vent = $scope.selectedConnections[0]
			$scope.fittingMeta = FittingMetaUtil.getFittingMeta(vent, $scope.design, $scope.fittings);
			//re-inject
			$scope.selectedConnections[0].Fit1 = vent.Fit1
			$scope.vent.Fit1 = vent.Fit1
			_hasErrors()
		}
	}
	$scope.isHorizontal = function() {
		return LengthUtil.getHorizontalLength($scope.selectedConnections[0], $scope.design) > 0 
	}
	$scope.isLastSection = function() {
		if ($scope.selectedConnections.length == 1){
			var vent = $scope.selectedConnections[0];
			var to = Util.getOutlet(vent, $scope.design)
			return to ? false : true
		} else {
			return false
		}
	}
	function load(conns){
		if (!Array.isArray(conns)) {
			conns = [conns];
		}
		$scope.selectedConnections = conns;

		var payload = {
			ECM: {}
		};
		var first = $scope.selectedConnections[0];
		$scope.ventGUIidentPath = first.GUIidentPath
		getExcludeMeta()
		$scope.sawtoothSectionMeta = $scope.getSawtoothSectionMeta()
		var excludeKeys = ["$$hashKey", "warning", "Selected", "ProcessCheck","GUIident", "GUIidentPath", "systemId"]
		_.each(_.keys(first), function(key){
			if (_.contains(excludeKeys, key)){
				return
			}
			var value = first[key];

			var diff = _.find($scope.selectedConnections, function(o){
				return JSON.stringify(o[key]) != JSON.stringify(first[key])
			})
			if (!diff){
				if (key == "Dim1" || key == "Dim2") {
					value = Util.formatLength(value, true, $scope.design)
				} else if (key == "DimX" || key=="DimZ"||key=="DimY" || key == "Length"){
					value = Util.formatLength(value, false, $scope.design)
				} if (key == "ECM") {
					if (value) {
						value = _.clone(value)
					}
				}
				payload[key] = value 
			}
		})
		if (!payload.ECM){
			payload.ECM = {}
		}
		// payload.ECM.economizer = Util.getSelectedEconmizer($scope.design.economizer, $scope.economizers)
		payload.ECM.economizer = $scope.design.economizer
		$scope.sectionBomItems = getSectionBomItems()
		$scope.vent = payload;

		$scope.selectionMeta = null
		$scope.selectedStartingProduct = null
		$scope.selectedStartingProductNavId = null
		if ($scope.vent.Fit1 === 'STP') {
			if ($scope.selectedConnections.length == 1) {
				$scope.selectionMeta = SelectionMetaUtil.getSelectionMeta($scope.selectedConnections[0], $scope.design)
				if ($scope.selectionMeta.startingPointProduct) {
					Product.get({
						id: $scope.selectionMeta.startingPointProduct
					}).$promise.then(function(product){
						$scope.selectedStartingProduct = product
						$scope.selectedStartingProductNavId = product && product.navId
					})
				}
			}
		}
		
		$scope.ventStackOptions = getStackOptions()
		$scope.fittingMeta = null
		$scope.fittingMeta2 = null
		if ($scope.selectedConnections.length == 1){
			var vent = $scope.selectedConnections[0];
			var to = Util.getOutlet(vent, $scope.design)
			var GUIidentPath = vent.GUIidentPath
			var GUIident = vent.GUIident
			if ($scope.design.stack && $scope.design.include.stack) {
				var fittingMeta = FittingMetaUtil.getFittingMeta(vent, $scope.design, $scope.fittings, false, true);
				FittingMetaUtil.hydrateFittingMeta(fittingMeta, $scope.design, $scope.cloneProducts).then(function(hydrated){
					$scope.fittingMeta = hydrated;
					_hasErrors()
				})
				if (to) {
					$scope.vent2 = to
					var fittingMeta2 = FittingMetaUtil.getFittingMeta(to, $scope.design, $scope.fittings, false, true);
					FittingMetaUtil.hydrateFittingMeta(fittingMeta2, $scope.design, $scope.cloneProducts).then(function(hydrated){
						$scope.fittingMeta2 = hydrated;
					})
				}
			}
			$scope.fittingTargets = getFittingTargets();
			$scope.identNum1 = $scope.hasMultipleSystems() ? GUIidentPath + '.1' : GUIident + ".1"
			$scope.identNum2 = $scope.hasMultipleSystems() ? GUIident + '.2': GUIident + ".2"
		}
		// if ($scope.selectedConnections.length == 1){
		// 	if (Util.Ais1($scope.vent, $scope.design) == true) {
		// 		$scope.flipFit = false;
		// 	} else {
		// 		$scope.flipFit = true;
		// 		var t1 = $scope.vent.Fit1,
		// 			t2 = $scope.vent.Fit2;
		// 		$scope.vent.Fit1 = t2;
		// 		$scope.vent.Fit2 = t1;
		// 	}
		// }

		$scope.ais1 = Util.Ais1(first, $scope.design);
		$scope.fitting1.options = Util.getFittings($scope.design.fc5layout.Layout, $scope.vent, $scope.design, $scope.fittings, true);
		$scope.fitting2.options = Util.getFittings($scope.design.fc5layout.Layout, $scope.vent, $scope.design, $scope.fittings, false);
		$scope.ventMaterials = Util.availableVentMaterials($scope.applianceCategories, $scope.design, $scope.allVentMaterials);
		getProductSubtypes()
		
		_hasErrors()
		updateLength()
		checkObj = {};
		if (prevWatch){
			prevWatch();
		}
		if (prevWatch2){
			prevWatch2();
		}
		prevWatch = $scope.$watchCollection("vent", function(newObj, oldObj){
			_.each(_.keys(newObj), function(key) {
				if (newObj[key] != (oldObj && oldObj[key])){
					if (key=="NetLength" || key == "ID" || newObj.ID != payload.ID){
						return
					}
					checkObj[key] = true;
				}
			})
		})
		if ($scope.supportECM){
			prevWatch2 = $scope.$watchCollection("vent.ECM", function(newObj, oldObj){
				_.each(_.keys(newObj), function(key) {
					var newVal = newObj[key],
						oldVal = (oldObj && oldObj[key]);
					if (newVal != oldVal){
						checkObj.ECM = true;
						// if (key == "ID" || newObj.ID != payload.ID){
						// 	console.log("ingnoring "+key)
						// 	return
						// }
						// console.log("set"+key)
						// checkObj[key] = true;
					}
				})
			})
		}

	}
	load($scope.selectionManager.selectedVents());

	function _setProps() {
		var checked = false
		if ($scope.selectedConnections.length == 1) {
			$scope.transformIds($scope.design, {
				force: true
			})
		}
		_.each($scope.selectedConnections, function(v){
			var isEcm = ($scope.vent.Fit1 == 'ECM' || $scope.vent.Fit1 == 'ECO' || $scope.vent.Fit2 == 'ECM' || $scope.vent.Fit2 == 'ECO');
			var excludeKeys = ["ID", "result", "ECM"]
			_.each(_.keys(checkObj), function(key){
				if (_.contains(excludeKeys, key)){
					return
				}
				var value = $scope.vent[key];
				if (key == "DimY" || key=="DimX" || key == "DimZ" || key == "AddK" || key == "AddPres" || key== "Diameter" || key== "Dim1" || key== "Dim2" || key== "Length"){
					value = Util._float(Util.changeDim(value, $scope.design))
				} else if (key =="Emb90" || key == "Emb45"){
					value = Util._int(value);
				} else if (key == "Fit1" || key == "Fit2") {
					var prop = key;
					if (!Util.Ais1(v, $scope.design)) {
						if (prop == "Fit1"){
							prop = "Fit2"
						} else {
							prop = "Fit1"
						}
					}
					setValue(prop, value, "str",v);
					return
				}
				if (key == 'Diameter') {
					value = value.toString();
				}
				v[key] = value;
			})
			if ($scope.supportECM && (checkObj.ECM || isEcm)){
				if (isNaN($scope.vent.ECM.KValue) || $scope.vent.ECM.KValue <= 0) {
					throw "Please enter a valid ECM K-Value";
				}
				if (isNaN($scope.vent.ECM.MaxT) || $scope.vent.ECM.MaxT <= 0) {
					throw "Please enter a valid ECM max temperature."
				}
				if (isNaN($scope.vent.ECM.Dia) || $scope.vent.ECM.Dia <= 0) {
					throw "Please enter a valid ECM diameter."
				}
				//make sure this is set before setValue is called
				Util.updateEconomizerValue($scope.design, $scope.vent.ECM)
			}
		})
		$scope.selectionManager.updatedVents()
	}
	function _hasErrors() {
		$scope.errors = {}
		_.each(["DimX", "DimY", "DimZ","VentType", "Shape", "Fit1", "Fit2", "Dim1", "Dim2"], function(prop){
			$scope.errors[prop] = hasError(prop)
		})
		if ($scope.selectedConnections.length == 1 && $scope.design.include.stack && $scope.fittingMeta){
			var v = $scope.selectedConnections[0]
			Util.setFit1(v, $scope.vent.Fit1, $scope.design)
			$scope.fittingMetaErrors = FittingMetaUtil.validateVent(v, $scope.fittingMeta, $scope.design, $scope.fittings)
		} else {
			$scope.fittingMetaErrors = {}
		}
	}
	$scope.hasFittingError = function(name) {
		return $scope.fittingMetaErrors && fittingMetaErrors[name] ? true : false
	}
	$scope.getFittingError = function(name) {
		var result = $scope.fittingMetaErrors && fittingMetaErrors[name]
		return result || ''
	}
	$scope.fittingMetaChanged = function(prop){
		_hasErrors()
	}
	$scope.checkErrors = function(prop){
		_hasErrors()
		updateLength()
	}
	function updateLength() {
		if ($scope.selectedConnections.length == 1){
			$scope.vent.NetLength = LengthUtil.getLengthFromVAsString($scope.vent, $scope.design)
		}
	}
	$scope.fitting2Changed = function() {
		if ($scope.selectedConnections.length == 1 && $scope.design.include.stack) {
			if ($scope.fittingMeta2 && $scope.fittingMeta2.autofitMode == "autofitComplete") {
				$scope.fittingMeta2.autofitMode = "autofit"
			}
		}
	}
	$scope.fitting1Changed = function() {
		if ($scope.selectedConnections.length == 1 && $scope.design.include.stack) {
			if ($scope.fittingMeta && $scope.fittingMeta.autofitMode == "autofitComplete") {
				$scope.fittingMeta.autofitMode = "autofit"
			}
		}
		if ($scope.selectedConnections.length == 1 && $scope.design.include.stack) {
			var v = $scope.selectedConnections[0]
			var fromFitting = _.find($scope.fittings, function(fitting){
				return fitting.code == v.Fit1
			})
			var toFitting = _.find($scope.fittings, function(fitting){
				return fitting.code == $scope.vent.Fit1
			})
			if (toFitting.secno != fromFitting.secno) {
				Util.setFit1(v, $scope.vent.Fit1, $scope.design)
				FittingMetaUtil.getFittingMeta(v, $scope.design, $scope.fittings, true, true)
			}
		}
		$scope.checkErrors()
	}
	$scope.autofitCap = function() {
		if ($scope.selectedConnections.length == 1 && $scope.design.include.stack) {
			var vent = $scope.selectedConnections[0]
			var fitting = _.find($scope.fitting1.options, function(fitting){
				return fitting.code == $scope.vent.Fit1
			})
			var inlets = Util.getInlets(vent, $scope.design)
			var coverType = LayoutUtil.get3wayCover(vent, $scope.design)
			if (fitting && fitting.secno == 3) {
				if (inlets.length == 1 && coverType) {
					if (!$scope.fittingMeta.centerPointC) {
						$scope.fittingMeta.centerPointC = {}
					}
					$scope.fittingMeta.centerPointC.type = "productSubtype"
					$scope.fittingMeta.centerPointC.productSubtype = coverType
				}
			}
			if (fitting && fitting.secno == 4) {
				if (inlets.length == 2 && coverType) {
					if (!$scope.fittingMeta.centerPointD) {
						$scope.fittingMeta.centerPointD = {}
					}
					$scope.fittingMeta.centerPointD.type = "productSubtype"
					$scope.fittingMeta.centerPointD.productSubtype = coverType
				}
			}
			//$scope.apply()
		}
	}
	function hasError(prop){
		if ($scope.selectedConnections.length > 1){
			return false;
		}
		var v = $scope.selectedConnections[0];
		var direction = Util.direction(v, $scope.design)
		if (prop == "DimX") {
			if (v.DimX == 0 && direction.x){
				console.log("errored X based on ", _.pick(v,"DimX", "X1", "X2","Y1","Y2","Z1","Z2"))
				return true;
			}
		}
		if (prop == "DimY"){
			if (v.DimY == 0 && direction.y){
				console.log("errored Y based on ", _.pick(v,"DimY", "X1", "X2","Y1","Y2","Z1","Z2"))
				return true;
			}
		}
		if ($scope.design.viewMode == "3D" && prop == "DimZ"){
			if (v.DimZ == 0 && direction.z){
				console.log("errored Z based on ", _.pick(v,"DimZ", "X1", "X2","Y1","Y2","Z1","Z2"))
				return true;
			}
		}
		if (prop == "Dim1") {
			if ($scope.vent.Optimize == "No" && (!$scope.vent.Dim1 || $scope.vent.Dim1 == 0 || $scope.vent.Dim1 == "0")){
				return true
			}
		}
		if (prop == "Dim2"){
			if ($scope.vent.Optimize == "No" && (!$scope.vent.Dim2 || $scope.vent.Dim2 == 0 || $scope.vent.Dim2 == "0") && !($scope.vent.Shape == "ROU" || $scope.vent.Shape == "SQU") ){
				return true
			}
		}
		if (prop == "VentType"){
			var valid = _.find($scope.ventMaterials, function(vm){
				return vm.code == $scope.vent.VentType;
			})
			if (valid){
				return false
			} else {
				return true
			}
		}
		if (prop == "Shape"){
			var valid = _.find($scope.cmbShape.options, function(vm){
				return vm.value == $scope.vent.Shape;
			})
			if (valid){
				return false
			} else {
				return true
			}
		}
		if (prop == "Fit1"){
			var valid = _.find($scope.fitting1.options, function(vm){
				return vm.code == $scope.vent.Fit1;
			})
			if (valid){
				return false
			} else {
				return true
			}
		}
		if (prop == "Fit2"){
			var valid = _.find($scope.fitting2.options, function(vm){
				return vm.code == $scope.vent.Fit2;
			})
			if (valid){
				return false
			} else {
				return true
			}
		}
		return false
	}
	$scope.okVentData = function() {
		if (!$scope.applyVent()) {
			return
		}
		$scope._toSelectMode();
		var modalInstance = $uibModalInstance.close($scope.selectedConnections);
	}
	$scope.canVentData = function() {
		var modalInstance = $uibModalInstance.close($scope.selectedConnections);
	}
	$scope.canNext = function() {
		if (!$scope.selectedConnections) {
			return false
		} else if ($scope.selectedConnections.length != 1){
			return false;
		} else {
			var _next = $scope.selectionManager.nextVent();
			return (_next.length == 1)
		}
	}
	$scope.next = function() {
		if (!$scope.applyVent()) {
			return
		}
		var _next = $scope.selectionManager.nextVent();
		if (_next.length == 1) {
			$scope.selectionManager.unselectAll()
			$scope.selectionManager.selectVent(_next[0])
			load(_next)
			paint()
		} else if (_next.length == 0){
			alert("you are at the end")
		} else {
			alert("too many next paths")
		}
	}
	$scope.canPrevious = function() {
		if (!$scope.selectedConnections) {
			return false
		} else if ($scope.selectedConnections.length != 1){
			return false;
		} else {
			var _previous = $scope.selectionManager.previousVent();
			return (_previous.length == 1)
		}
	}
	$scope.previous = function() {
		if (!$scope.applyVent()) {
			return
		}
		var _previous = $scope.selectionManager.previousVent();
		if (_previous.length == 1) {
			$scope.selectionManager.unselectAll()
			$scope.selectionManager.selectVent(_previous[0])
			load(_previous);
			paint();
		} else if (_previous.length == 0){
			alert("you are at the begining")
		} else {
			alert("too many prvious paths")
		}
	}
	$scope.applyVent = function() {
		try {
			if ($scope.selectedConnections.length == 1 && $scope.design.include.stack){
				FittingMetaUtil.dehydrateFittingMeta($scope.fittingMeta)
			}
			_setProps();
			CommandManager.createCommand($scope.design, {
				name: "Edited Vent"
			},$scope.checkDirty)
			console.log("partial applyVent")
		}catch(e){
			var msg = (e.message) ? e.message : ""+e
			console.log("error", e)
			alert(msg)
			return false
		}
		paint()
		// _.each($scope.selectedConnections, function(connection) {
		// 	var X1 = connection.X1;
		// 	var X2 = connection.X2;
		// 	var Y1 = connection.Y1;
		// 	var Y2 = connection.Y2;

		// 	if (connection.Fit1 && connection.Fit1.code) {
		// 		var fit1 = connection.Fit1.code;
		// 	} else {
		// 		var fit1 = connection.Fit1
		// 	}

		// 	if (connection.Fit2 && connection.Fit2.code) {
		// 		var fit2 = connection.Fit2.code;
		// 	} else {
		// 		var fit2 = connection.Fit2
		// 	}
		// 	$scope.DrawEnds(context, X1, Y1, X2, Y2, fit1, fit2, connection);
		// })
		// $scope._toSelectMode()
		// $scope.MouseUp(0, 0, 0, false, "mouse");
		$scope._validate();
		console.log("done applyVent")
		return true;
	}
});
